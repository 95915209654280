import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpRequest, HttpClient, HttpResponse, HttpErrorResponse } from "@angular/common/http";
import { HttpInterceptor } from "@angular/common/http";

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from "../services/auth.service";

@Injectable()
export class ForbiddenInterceptor implements HttpInterceptor {

   constructor(
      public http: HttpClient,
      private authService: AuthService
   ) { }
   intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      return next.handle(request).pipe(catchError(err => {
         if (err.status === 403) {
            this.authService.logout();
            location.reload(true);
         }

         const error = { message: err.error || err.statusText }
         return throwError(error);
      }))
      /*       return next.handle(request).catch((err, caught) => {
               if ((err.status === 0) || (err.status === 401) || (err.status === 403)) {
                  switch (err.status) {
                     case 0:
                        window.alert('The server is offline. Please try again later.');
                        break;
      
                     case 401:
                        //window.alert('Invalid Username/Password.');
                        break;
      
                     case 403:
                        window.alert('Access Denied. You are not authorized to access this page.');
      
                        this.authService.logout();
                        location.reload(true);
                        break;
                  }
               }
               console.log(err)
               const error = err.error.message || err.statusText;
               return Observable.throw(error)
            })  */
   }
}