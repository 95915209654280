import { Observable } from 'rxjs';
export interface Sensor {
    _id?: string;
    nickn?: string;
    type?: string;
    category?: string;
    uid?: string;
    addr?: string;
    polen?: string;
    gateway?: string;
    asset_sensor?: string;
    location?: {
       x?: number,
       y?: number
    };
    street?: string;
    region?: string;
    state?: string;
    country?: string;
    full_address?: string;
    rainfall?: number;
    uv_intensity?: number;
    pm2_5?: number;
    pm10?: number;
    temperature?: number;
    noise?: number;
    humidity?: number;
    air_pressure?: number;
    wind_direction?: string;
    wind_speed?: number;
    optical_radiation?: number;
    lux?:  number;
    online?: number;
    refresh_time?: string;
    customer?: string;
    project?:[string];
    job?: string;
    create_date?: string;
    install_date?: string;
    warr?: number
 }
export abstract class SensorData {

   abstract observer(): Observable<Sensor[]>;
   
   abstract sensors():Sensor[];
       abstract updateSensor(luminaire: Sensor[]): Promise<Sensor[]>;
    abstract createSensor(luminaire: Sensor): Promise<Sensor>;
    abstract deleteSensor(luminaire: Sensor): Promise<Sensor>;
}