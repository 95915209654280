import { Injectable } from '@angular/core';
import { CustomerData, Customer } from '../data/customers';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { AppConfig } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerService extends CustomerData{

  subject: BehaviorSubject<Customer[]> = new BehaviorSubject<Customer[]>(null);

  constructor(
    private appConfig: AppConfig,
    private http: HttpClient
  ) {
    super();

    this.getCustomers().then((customers: Customer[])=>{
      console.log("[CustomerService] customer:", customers);
      if(customers.length >= 0) {
        this.subject.next(customers)
      }
    })

   }

   observer() {
    return this.subject;
  }

   customers() {
     return this.subject.value;
   }

   async getCustomers() {
    try {
      const httpOptions = {
        withCredentials: true,
      };

      return await this.http.get<Customer[]>(this.appConfig.baseUrl + '/user', httpOptions).toPromise();
    } catch (err) {
      throw err;
    }
  }

  async createUser(user: Customer) {
    try {
      const httpOptions = {
        withCredentials: true,
      };

      return await this.http.post<Customer>(this.appConfig.baseUrl + '/user', user, httpOptions).toPromise();
    } catch (err) {
      throw err;
    }
  }

  async updateUser(user: Customer) {
    try {
      const httpOptions = {
        withCredentials: true,
      };

      return await this.http.post<Customer>(this.appConfig.baseUrl + '/user/update', user, httpOptions).toPromise();
    } catch (err) {
      throw err;
    }
  }

  async deleteUser(user: Customer) {
    try {
      const httpOptions = {
        withCredentials: true,
      };

      return await this.http.delete<Customer>(this.appConfig.baseUrl + '/user/'+ user._id, httpOptions).toPromise();
    } catch (err) {
      throw err;
    }
  }
  getCustomerName(value) {

    let newValue;
    if (value) {
      if (value != null && this.customers) {
        newValue = this.customers().find(x => x._id == value) ? this.customers().find(x => x._id == value).usern : '';
      }
      else {
        newValue = ""
      }

    }
    return newValue;
  }

}
