import { Injectable } from '@angular/core';
import { GatewayData, Gateway } from '../data/gateway';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AppConfig } from './app-config.service';
import { Customer } from '../data/customers';


@Injectable({
  providedIn: 'root'
})
export class GatewayService extends GatewayData {

  subject: BehaviorSubject<Gateway[]> = new BehaviorSubject<Gateway[]>(null);

  constructor(
    private http: HttpClient,
    private appConfig: AppConfig,

  ) {
    super();

    this.findGateway().then((res: Gateway[]) => {
      console.log("[GatewayService] gateway:", res);
      if (res.length > 0) {
        res.forEach(gateway=>{
          if(!gateway.location) {
            gateway.location = {
              x: null,
              y: null
            }
          }
        })
        this.subject.next(res);
      }
    })
  }


  observer() {
    return this.subject.asObservable();
  }

  gateways() {
    return this.subject.value;
  }

  async findGateway() {
    try {
      const httpOptions = {
        withCredentials: true,
      };

      return await this.http.get<Gateway[]>(this.appConfig.baseUrl + '/gateway', httpOptions).toPromise();
    } catch (err) {
      throw err;
    }
  }

  async assignCustomer(gateway: Gateway, customerID) {
    try {
      const httpOptions = {
        withCredentials: true,
      };

      return await this.http.post<Gateway[]>(this.appConfig.baseUrl + '/gateway/customer',{customer: customerID, gateway: gateway}, httpOptions).toPromise();
    } catch (err) {
      throw err;
    }
  }
  async updateGateway(gateway: Gateway[]) {
    try {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        withCredentials: true,
      };


      return await this.http.post<Gateway[]>(this.appConfig.baseUrl + '/gateway/update',gateway, httpOptions).toPromise();
    } catch (err) {
      throw err;
    }
  }
  async getGatewaysFilter(filter:{}){
    try {
      let key:string=Object.keys(filter)[0]
      let value:string=filter[key]
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        params: new HttpParams({
        }).set(key,value),
        withCredentials: true,
      };
      return await this.http.get<Gateway[]>(this.appConfig.baseUrl + '/gateway', httpOptions).toPromise();
    } catch (err) {
      throw err;
    }
  }
  
  getGatewayName(value) {
    let newValue;
    if (value) {
      if (value != null ) {
        newValue = this.gateways().find(x => x._id == value) ? this.gateways().find(x => x._id == value).nickn || this.gateways().find(x => x._id == value).addr : '-';
      }
      else {
        newValue = "-"
      }

    }
    return newValue;
  }

  async deleteGateway(gateway: Gateway[]) {
    try {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        withCredentials: true,
      };

      console.log('gateway in service: ', gateway)
      console.log('gateway_id in service: ', gateway[0]._id)
      return await this.http.delete<any>(this.appConfig.baseUrl + '/gateway/' + gateway[0]._id , httpOptions).toPromise();
    } catch (err) {
      throw err;
    }
  }
}
