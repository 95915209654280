import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent,HttpResponse }  from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class httpsInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    console.log(req.url);

    const secureReq = req.clone({
      url: req.url.replace('http://', 'https://')
    });

    return next.handle(secureReq);
  }
}