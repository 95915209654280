import { Injectable } from '@angular/core';
import { AssetData, Asset, AssetLamp, AssetGateway, AssetLightSource, AssetNema, AssetDriver } from '../data/assets';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class AssetService extends AssetData {

  subject: BehaviorSubject<Asset> = new BehaviorSubject<Asset>(null);

  settingLampInfo = {
    attr: {
      class: 'tabelnew' // this is custom table scss or css class for table
    },
    defaultStyle: false,
    selectMode: 'multi',
    hideSubHeader: true,
    actions: {
      columnTitle: 'Action',
      add: false,
      delete: false,
      edit: false,
      select: true,
    },
    pager: {
      perPage: 5,
    },
    columns: {
      nickn: { title: "Name" },
      category: { title: "Category" },
      brand: { title: "Brand" },
      model: { title: "Model" },
      norminal_watt: { title: "Norminal Wattage (W)" },
      luminous_efficacy: { title: "Luminous Efficacy (lm/W)" },
      luminous_flux: { title: "Luminous Flux (lm)" },
      ambient_temp_rating: { title: "Ambient Temperature Rating (°C)" },
      weight: { title: "Weight (kg)" },
      lifetime: { title: "Lifetime (hours)" },
      casing_material: { title: "Casing Material" },
      casing_color: { title: "Casing Color" },
      warr_years: { title: "Warranty (years)" },
      comment: { title: "Comment" },
    }
  }

  settingGatwInfo = {
    attr: {
      class: 'tabelnew' // this is custom table scss or css class for table
    },
    defaultStyle: false,
    selectMode: 'multi',
    hideSubHeader: true,
    actions: {
      columnTitle: 'Action',
      add: false,
      delete: false,
      edit: false,
      select: true,
    },
    columns: {
      nickn: { title: "Name" },
      vendor: { title: "Vendor" },
      brand: { title: "Brand" },
      model: { title: "Model" },
      category: { title: "Category" },
      comm_protocol: { title: "Comm Protocol" },
      supplier: { title: "Supplier" },
      comment: { title: "Comment" }
    }
  }

  settingDrivInfo = {
    attr: {
      class: 'tabelnew' // this is custom table scss or css class for table
    },
    defaultStyle: false,
    selectMode: 'multi',
    hideSubHeader: true,
    actions: {
      columnTitle: 'Action',
      add: false,
      delete: false,
      edit: false,
      select: true,
    },
    pager: {
      perPage: 10,
    },
    columns: {
      nickn: { title: "Name" },
      brand: { title: "Brand" },
      model: { title: "Model" },
      vendor: { title: "Vendor" },
      category: { title: "Category" },
      watt_rating: { title: "Watt Rating (W)" },
      min_current: { title: "Min Current (A)" },
      max_current: { title: "Max Current (A)" },
      min_voltage: { title: "Min Voltage (V)" },
      max_voltage: { title: "Max Voltage (V)" },
      min_dimming: { title: "Min. Dimming (%)" },
      pwr_failure_threshold: { title: "Power Failure Threshold (W)" },
      dim_interface: { title: "Driver Dimming Interface" },
      max_burn_hrs: { title: "Max Burn Hours" },
      comment: { title: "Comment" },
    }
  }

  settingNemaInfo = {
    attr: {
      class: 'tabelnew' // this is custom table scss or css class for table
    },
    defaultStyle: false,
    selectMode: 'multi',
    hideSubHeader: true,
    actions: {
      columnTitle: 'Action',
      add: false,
      delete: false,
      edit: false,
      select: true,
    },
    pager: {
      perPage: 10,
    },
    columns: {
      nickn: { title: "Name" },
      brand: { title: "Brand" },
      model: { title: "Model" },
      vendor: { title: "Vendor" },
      category: { title: "Category" },
      comm_protocol: { title: "Comm Protocol" },
      comment: { title: "Comment" },
    }
  }

  settingLightSourceInfo = {
    attr: {
      class: 'tabelnew' // this is custom table scss or css class for table
    },
    defaultStyle: false,
    selectMode: 'multi',
    hideSubHeader: true,
    actions: {
      columnTitle: 'Action',
      add: false,
      delete: false,
      edit: false,
      select: true,
    },
    pager: {
      perPage: 10,
    },
    columns: {
      nickn: { title: "Name" },
      brand: { title: "Brand" },
      model: { title: "Model" },
      vendor: { title: "Vendor" },
      category: { title: "Category" },
      lamp_technology: { title: "Lamp Technology" },
      color_temperature: { title: "Color Temperature (K)" },
      CRI: { title: "CRI" },
      light_source_color: { title: "Light Source Color" },
      max_burn_hrs: { title: "Max Burn Hours (hours)" },
      comment: { title: "Comment" },
    }
  }

  constructor(
    private http: HttpClient,
    private appConfig: AppConfig,
  ) {
    super();

    // this.subject.next(this.assets)

    this.getAsset().then((asset: Asset)=> {
      if (asset) {
        this.subject.next(asset);
        console.log("[AssetService] Assets", asset)
      }
    })
  }

  observer() {
    return this.subject;
  }


  async getAsset() {
    try {
      const httpOptions = {
        withCredentials: true,
      };

      return await this.http.get<Asset>(this.appConfig.baseUrl + '/asset', httpOptions).toPromise();
    } catch (err) {
      throw err;
    }
  }

  async createAssetLamp(asset: AssetLamp) {
    try {
      const httpOptions = {
        withCredentials: true,
      };

      return await this.http.post<AssetLamp>(this.appConfig.baseUrl + '/asset/lamp', asset, httpOptions).toPromise();
    } catch (err) {
      throw err;
    }
  }

  async updateAssetLamp(asset: AssetLamp) {
    try {
      const httpOptions = {
        withCredentials: true,
      };

      return await this.http.post<AssetLamp>(this.appConfig.baseUrl + '/asset/lamp/update', asset, httpOptions).toPromise();
    } catch (err) {
      throw err;
    }
  }

  async deleteAssetLamp(asset: AssetLamp) {
    try {
      const httpOptions = {
        withCredentials: true,
      };

      return await this.http.delete<AssetLamp>(this.appConfig.baseUrl + '/asset/lamp/' + asset._id, httpOptions).toPromise();
    } catch (err) {
      throw err;
    }
  }

  async createAssetGateway(asset: AssetGateway) {
    try {
      const httpOptions = {
        withCredentials: true,
      };

      return await this.http.post<AssetGateway>(this.appConfig.baseUrl + '/asset/gateway', asset, httpOptions).toPromise();
    } catch (err) {
      throw err;
    }
  }

  async updateAssetGateway(asset: AssetGateway) {
    try {
      const httpOptions = {
        withCredentials: true,
      };

      return await this.http.post<AssetGateway>(this.appConfig.baseUrl + '/asset/gateway/update', asset, httpOptions).toPromise();
    } catch (err) {
      throw err;
    }
  }

  async deleteAssetGateway(asset: AssetGateway) {
    try {
      const httpOptions = {
        withCredentials: true,
      };

      return await this.http.delete<AssetGateway>(this.appConfig.baseUrl + '/asset/gateway/'+ asset._id, httpOptions).toPromise();
    } catch (err) {
      throw err;
    }
  }

  async createAssetLightSource(asset: AssetLightSource) {
    try {
      const httpOptions = {
        withCredentials: true,
      };

      return await this.http.post<AssetLightSource>(this.appConfig.baseUrl + '/asset/light_source', asset, httpOptions).toPromise();
    } catch (err) {
      throw err;
    }
  }

  async updateAssetLightSource(asset: AssetLightSource) {
    try {
      const httpOptions = {
        withCredentials: true,
      };

      return await this.http.post<AssetLightSource>(this.appConfig.baseUrl + '/asset/light_source/update', asset, httpOptions).toPromise();
    } catch (err) {
      throw err;
    }
  }

  async deleteAssetLightSource(asset: AssetLightSource) {
    try {
      const httpOptions = {
        withCredentials: true,
      };

      return await this.http.delete<AssetLightSource>(this.appConfig.baseUrl + '/asset/light_source/' + asset._id, httpOptions).toPromise();
    } catch (err) {
      throw err;
    }
  }

  async createAssetNema(asset: AssetNema) {
    try {
      const httpOptions = {
        withCredentials: true,
      };

      return await this.http.post<AssetNema>(this.appConfig.baseUrl + '/asset/nema', asset, httpOptions).toPromise();
    } catch (err) {
      throw err;
    }
  }

  async updateAssetNema(asset: AssetNema) {
    try {
      const httpOptions = {
        withCredentials: true,
      };

      return await this.http.post<AssetNema>(this.appConfig.baseUrl + '/asset/nema/update', asset, httpOptions).toPromise();
    } catch (err) {
      throw err;
    }
  }

  async deleteAssetNema(asset: AssetNema) {
    try {
      const httpOptions = {
        withCredentials: true,
      };

      return await this.http.delete<AssetNema>(this.appConfig.baseUrl + '/asset/nema/'+ asset._id, httpOptions).toPromise();
    } catch (err) {
      throw err;
    }
  }

  async createAssetDriv(asset: AssetDriver) {
    try {
      const httpOptions = {
        withCredentials: true,
      };

      return await this.http.post<AssetDriver>(this.appConfig.baseUrl + '/asset/driver', asset, httpOptions).toPromise();
    } catch (err) {
      throw err;
    }
  }

  async updateAssetDriv(asset: AssetDriver) {
    try {
      const httpOptions = {
        withCredentials: true,
      };

      return await this.http.post<AssetDriver>(this.appConfig.baseUrl + '/asset/driver/update', asset, httpOptions).toPromise();
    } catch (err) {
      throw err;
    }
  }

  async deleteAssetDriv(asset: AssetDriver) {
    try {
      const httpOptions = {
        withCredentials: true,
      };

      return await this.http.delete<AssetDriver>(this.appConfig.baseUrl + '/asset/driver/'+ asset._id, httpOptions).toPromise();
    } catch (err) {
      throw err;
    }
  }
}
