import { Observable } from 'rxjs';

export interface Asset {
    lamp: AssetLamp[],
    gateway: AssetGateway[],
    light_source: AssetLightSource[],
    nema: AssetNema[],
    driver: AssetDriver[]
}

export interface AssetLamp {
    _id?: string,
    nickn?: string,
    vendor?: string,
    brand?: string,
    model?: string,
    category?: string,
    norminal_watt?: number,
    luminous_efficacy?: number,
    luminous_flux?: number,
    ambient_temp_rating?: number,
    casing_color?: string,
    casing_material?: string,
    weight?: number,
    lifetime?: number,
    warr_years?: number,
    light_source?: AssetLightSource,
    nema?: AssetNema,
    driver?: AssetDriver,
    comment?: string,
}

export interface AssetGateway {
    _id?: string,
    nickn?: string,
    vendor?: string,
    brand?: string,
    model?: string,
    category?: string,
    comm_protocol?: string,
    supplier?: string,
    comment?: string,
}

export interface AssetLightSource {
    _id?: string,
    nickn?: string,
    vendor?: string,
    brand?: string,
    model?: string,
    category?: string,
    lamp_technology?: string,
    color_temperature?: number,
    CRI?: number,
    light_source_color?: string,
    max_burn_hrs?: number,
    comment?: string,
}

export interface AssetNema {
    _id?: string,
    nickn?: string,
    vendor?: string,
    brand?: string,
    model?: string,
    category?: string,
    comm_protocol?: string,
    comment?: string,
}

export interface AssetDriver {
    _id?: string,
    nickn?: string,
    vendor?: string,
    brand?: string,
    model?: string,
    category?: string,
    watt_rating?: number,
    max_current?: number,
    max_voltage?: number,
    min_current?: number,
    min_voltage?: number,
    max_burn_hrs?: number,
    min_dimming?: number,
    pwr_failure_threshold?: number,
    dim_interface?: string,
    comment?: string,
}

export abstract class AssetData {
    abstract observer(): Observable<Asset>;
    abstract settingLampInfo: Object;
    abstract settingGatwInfo: Object;
    abstract settingDrivInfo: Object;
    abstract settingNemaInfo: Object;
    abstract settingLightSourceInfo: Object;
    abstract createAssetLamp(asset: AssetLamp): Promise<AssetLamp>;
    abstract updateAssetLamp(asset: AssetLamp): Promise<AssetLamp>;
    abstract deleteAssetLamp(asset: AssetLamp): Promise<AssetLamp>;

    abstract createAssetGateway(asset: AssetGateway): Promise<AssetGateway>;
    abstract updateAssetGateway(asset: AssetGateway): Promise<AssetGateway>;
    abstract deleteAssetGateway(asset: AssetGateway): Promise<AssetGateway>;

    abstract createAssetDriv(asset: AssetDriver): Promise<AssetDriver>;
    abstract updateAssetDriv(asset: AssetDriver): Promise<AssetDriver>;
    abstract deleteAssetDriv(asset: AssetDriver): Promise<AssetDriver>;

    abstract createAssetNema(asset: AssetNema): Promise<AssetNema>;
    abstract updateAssetNema(asset: AssetNema): Promise<AssetNema>;
    abstract deleteAssetNema(asset: AssetNema): Promise<AssetNema>;

    abstract createAssetLightSource(asset: AssetLightSource): Promise<AssetLightSource>;
    abstract updateAssetLightSource(asset: AssetLightSource): Promise<AssetLightSource>;
    abstract deleteAssetLightSource(asset: AssetLightSource): Promise<AssetLightSource>;
}