import { Observable } from 'rxjs';
import { Customer } from './customers';

export interface Gateway {
  _id?: string;
  nickn?: string;
  type?: string;
  maddr?: string;
  addr?: string;
  addDate?: string;
  controlBoxNo?: string;
  asset_gateway?: string,
  location?: {
    x?: number,
    y?: number
  };
  street?: string;
  region?: string;
  state?: string;
  country?: string;
  full_address?: string;
  online?: number;
  phase_info?: {
    en: number;
    phase_id?: string;
    voltage?: number;
    current?: number;
    activePwr?: number;
    refresh_time?: string;
  }[];
  loop_info?: {
    en?: number;
    nickn?: string;
    loop_id?: string;
    uid?: string;
    id?: string;
    ep?: number;
    online?: number;
    voltage?: number;
    current?: number;
    activePwr?: number;
    refresh_time?: string;
    status?: number;
  }[];
  category: string;
  loops?: number[]; // [1(A1), 2(A2), 3(B1), 4(B2), 5(C1), 6(C2)]
  poles?: [];
  customer?: string;
  project?: string;
  job?: string,
  bright?: number,
  warr: number;
}

export abstract class GatewayData {
  abstract observer(): Observable<Gateway[]>;
  abstract gateways(): Gateway[];
  abstract assignCustomer(gateway: Gateway, customer: string): Promise<any>
  abstract updateGateway(gateway: Gateway[]): Promise<Gateway[]>;
  abstract getGatewaysFilter(filter:{}): Promise<any>
  abstract getGatewayName(value);
  abstract deleteGateway(gateway: Gateway[]): Promise<any>;

}
