import { Observable } from 'rxjs';

export interface Auth {
  _id:  string;
  name: string;
  email: string;
  usern: string;
}

export abstract class AuthData {
}
