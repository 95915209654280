import { Observable } from 'rxjs';

export interface Job {
  asset?:{}
  _id?:  string,
  name?: string,                      // mac address
  refn?: string,                     
  creationDate?: string,
  startDate?: string,  
  endDate?: string,              
  contractor?: string,
  pic?: string,
  worker?:string[],
  location?: string,
  lamps?: string[],
  gateways?:string[],
  status?: boolean; // true = active, false = completed
//   region?:string,
  comment?: string;
  customer?: string,
  newLamp?:[],
  newGatw?:[]
}

export abstract class JobData {
    abstract observer(): Observable<Job[]>; 
    abstract jobs(): Job[]; 
    abstract createJob(job: Job): Promise<any>
    abstract updateJob(job: Job[]): Promise<Job[]>;
    abstract deleteJob(job: Job[]): Promise<any>
    abstract addAsset(info:{},options:{mode:string,count:number}): Promise<any>
    
}
