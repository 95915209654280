import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { BehaviorSubject, Observable, Observer } from 'rxjs';
import { AppConfig } from './app-config.service';

import { Auth, AuthData } from '../data/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends AuthData {
  roleSubject: BehaviorSubject<string[]>;
  authSubject: BehaviorSubject<Auth>;

  constructor(
    private http: HttpClient,
    private appConfig: AppConfig
  ) {
    super();
    this.roleSubject = new BehaviorSubject<string[]>(['']);
    this.authSubject = new BehaviorSubject<Auth>(JSON.parse(localStorage.getItem('advancti/user')));

    console.log("[AuthService] constructor", this.roleSubject && this.roleSubject.value, this.authSubject && this.authSubject.value)
  }

  get currentUser(): Auth {
    return this.authSubject.value;
  }

  authEvents() {
    return this.authSubject.asObservable();
  }

  roleEvents() {
    return this.roleSubject.asObservable();
  }

  async login(username: string, password: string) {
    try {
      const body = { 'username': username, 'password': password };

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
        withCredentials: true,
      };

      let res = await this.http.post<Auth>(this.appConfig.baseUrl + '/login', body, httpOptions).toPromise();

      if (res) {
        localStorage.setItem('advancti/user', JSON.stringify(res));
        this.authSubject.next(res);

        return res;
      }
      return null;

    } catch (err) {
      throw err;
    }
  }

  async roles() {
    try {
      if(!this.currentUser) return;
      
      let res = await this.http.get<[]>(this.appConfig.baseUrl + '/user/' + this.currentUser._id, { withCredentials: true }).toPromise();

      console.log("[AuthService] roles", res);

      if (res)
        this.roleSubject.next(res);
      
    } catch (err) {
      if (err && err.error && err.error.errors) {
        throw err.error.errors[0];
      }
      throw err;
    }
  }

  authorized(role: string) {
    return (this.roleSubject.value.includes(role));
  }

  logout() {
    localStorage.removeItem('advancti/user');
    this.authSubject.next(null);
  }
}
