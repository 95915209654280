import { Injectable } from '@angular/core';
import { Luminaire, LuminaireData, LuminaireState } from '../data/luminaire';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AppConfig } from './app-config.service';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class LuminaireService extends LuminaireData {
  subject: BehaviorSubject<Luminaire[]> = new BehaviorSubject<Luminaire[]>(null);
  
  constructor(
    private http: HttpClient,
    private appConfig: AppConfig,

  ) {
    super();

    this.findLuminaire().then((luminaires: Luminaire[]) => {
      console.log("[LuminaireService] luminaires:", luminaires);

      if (luminaires.length >= 0) {
        luminaires.forEach(luminaire=>{
          if(!luminaire.location)
            luminaire.location = {
              x: null,
              y: null
            }  
            // if(!luminaire.status){
            //   luminaire.status=256
            // }
            // if(!luminaire.online){
            //   luminaire.online=0
            // }
        })

        this.subject.next(luminaires);

      }
    }, err => {
      console.error(err.message);
    });

   }


  observer() {
    return this.subject;
  }

  luminaires() {
    return this.subject.value;
  }

  async findLuminaire() {
    try {
      const httpOptions = {
        withCredentials: true,
      };

      return await this.http.get<Luminaire[]>(this.appConfig.baseUrl + '/luminaire', httpOptions).toPromise();
    } catch (err) {
      throw err;
    }
  }
  async createLuminaire(luminaire: Luminaire) {

    try {
      const httpOptions = {
        withCredentials: true,
      };
      console.log("new --> ", luminaire)
      return await this.http.post<Luminaire>(this.appConfig.baseUrl + '/luminaire/create', luminaire, httpOptions).toPromise();
    } catch (err) {
      throw err;
    }
  }
  async updateLuminaire(luminaire: Luminaire[]) {
    try {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        withCredentials: true,
      };

      return await this.http.post<Luminaire[]>(this.appConfig.baseUrl + '/luminaire/update',luminaire, httpOptions).toPromise();
    } catch (err) {
      throw err;
    }
  }
  
  async deleteLuminaire(luminaire: Luminaire[]) {
    try {
      let luminaires = []
      luminaire.forEach(p=>{
        luminaires.push('"'+p._id+'"');
      })
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        withCredentials: true,
      };

      console.log(luminaires)
      return await this.http.delete<any>(this.appConfig.baseUrl + '/luminaire/' + luminaire[0]._id , httpOptions).toPromise();
    } catch (err) {
      throw err;
    }
  }
  async associateLuminaire(group:any) {
    try {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        withCredentials: true,
      };

      return await this.http.post<[]>(this.appConfig.baseUrl + '/luminaire/associate',group, httpOptions).toPromise();
    } catch (err) {
      throw err;
    }
  }
  async getLuminairesFilter(filter:{}){
    try {
      let key:string=Object.keys(filter)[0]
      let value:string=filter[key]
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        params: new HttpParams({
        }).set(key,value),
        withCredentials: true,
      };
      return await this.http.get<Luminaire[]>(this.appConfig.baseUrl + '/luminaire', httpOptions).toPromise();
    } catch (err) {
      throw err;
    }
  }
}
