import { ProjectService } from './services/project.service';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NbAuthModule, NbDummyAuthStrategy } from '@nebular/auth';
import { NbSecurityModule, NbRoleProvider } from '@nebular/security';
import { of as observableOf } from 'rxjs';

import { throwIfAlreadyLoaded } from './module-import-guard';
import { AnalyticsService } from './utils';
import { UserData } from './data/users';
import { UserService } from './mock/users.service';
import { MockDataModule } from './mock/mock-data.module';

import { AuthData } from './data/auth';
import { AuthService } from './services/auth.service';
import { ColorService } from './utils/color.service'
// import { PeriodService } from './utils/period.service'



//import { UserData } from './data/users';
//import { UserService } from './mock/users.service';

import { LuminaireData } from './data/luminaire';
import { LuminaireService } from './services/luminaire.service';

import { CustomerData } from './data/customers';
import { CustomerService } from './services/customer.service';
import { GatewayData } from './data/gateway';
import { GatewayService } from './services/gateway.service';
import { ProjectData } from './data/project';
import { AssetData } from './data/assets';
import { AssetService } from './services/asset.service';
import { JobData } from './data/job';
import { JobService } from './services/job.service';
import { SensorData } from './data/sensor';
import { SensorService } from './services/sensor.service';

// 

const socialLinks = [
  {
    url: 'https://github.com/akveo/nebular',
    target: '_blank',
    icon: 'github',
  },
  {
    url: 'https://www.facebook.com/akveo/',
    target: '_blank',
    icon: 'facebook',
  },
  {
    url: 'https://twitter.com/akveo_inc',
    target: '_blank',
    icon: 'twitter',
  },
];

const DATA_SERVICES = [
  { provide: AuthData, useClass: AuthService },
  { provide: UserData, useClass: UserService },
  { provide: LuminaireData, useClass: LuminaireService },
  { provide: CustomerData, useClass: CustomerService },
  { provide: GatewayData, useClass: GatewayService },
  { provide: ProjectData, useClass: ProjectService },
  { provide: JobData, useClass: JobService },
  { provide: AssetData, useClass: AssetService },
  { provide: SensorData, useClass: SensorService },
];

export class NbSimpleRoleProvider extends NbRoleProvider {
  getRole() {
    // here you could provide any role based on any auth flow
    return observableOf('guest');
  }
}

export const NB_CORE_PROVIDERS = [
  ...MockDataModule.forRoot().providers,
  ...DATA_SERVICES,
  ...NbAuthModule.forRoot({

    strategies: [
      NbDummyAuthStrategy.setup({
        name: 'email',
        delay: 3000,
      }),
    ],
    forms: {
      login: {
        socialLinks: socialLinks,
      },
      register: {
        socialLinks: socialLinks,
      },
    },
  }).providers,

  NbSecurityModule.forRoot({
    accessControl: {
      guest: {
        view: '*',
      },
      user: {
        parent: 'guest',
        create: '*',
        edit: '*',
        remove: '*',
      },
    },
  }).providers,

  {
    provide: NbRoleProvider, useClass: NbSimpleRoleProvider,
  },
  AnalyticsService,
];

@NgModule({
  imports: [
    CommonModule,
  ],
  exports: [
    NbAuthModule,
  ],
  declarations: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: CoreModule,
      providers: [
        ...NB_CORE_PROVIDERS,
      ],
    };
  }
}
