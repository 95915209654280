import { Observable } from 'rxjs';

export interface Customer {
  _id: string,
  usern: string,
  email: string,
  passw: string,
  name: string,
  roles: string[]
}

export abstract class CustomerData {
  abstract observer(): Observable<Customer[]>;
  abstract customers(): Customer[];
  abstract createUser(user: Customer): Promise<Customer>;
  abstract deleteUser(user: Customer): Promise<Customer>;
  abstract updateUser(user: Customer): Promise<Customer>;
  abstract getCustomerName(value);

  }