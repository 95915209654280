import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AppConfig {
  appConfig: any;

  constructor(private http: HttpClient) { }

  loadAppConfig() {
/*     const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*'
      }),
      withCredentials: true,
    };

    return this.http.get('/assets/config.json', httpOptions)
      .toPromise()
      .then(data => {
        this.appConfig = data;
      }); */

      let cloud = true; // set true to connect to cloud; set false for local
      let dev = false; // false (gcloud) or true (amazon)

      let googleIp = "advancti.com"; //34.68.166.247
      let amazonIp = "18.191.252.191";

      this.appConfig = {
        "secure": cloud,
        // "host": cloud? google? "35.223.36.84" : "ec2-18-217-41-46.us-east-2.compute.amazonaws.com" : "localhost",
        "host": cloud? dev? amazonIp : googleIp : "localhost",
        "port": {
          "rest": cloud? '': ":2083",//"2083", // using Port 8000 for AWS "3000",
           // "mqtt": "8080", //"8884"
           "mqtt": cloud? "8080" : "8884",
        },
        "rapi": "/api/v1",
        "user": "advancti"
     }
  }

  // This is an example property ... you can make it however you want.
  get secure() {
    if (!this.appConfig) {
      throw Error('Config file not loaded!');
    }

    return this.appConfig.secure;
  }

  get host() {
    if (!this.appConfig) {
      throw Error('Config file not loaded!');
    }

    return this.appConfig.host;
  }

  get port() {
    if (!this.appConfig) {
      throw Error('Config file not loaded!');
    }

    return this.appConfig.port;
  }

  get rapi() {
    if (!this.appConfig) {
      throw Error('Config file not loaded!');
    }

    return this.appConfig.rapi;
  }

  get user() {
    if (!this.appConfig) {
      throw Error('Config file not loaded!');
    }

    return this.appConfig.user;
  }

  get baseUrl() {
    if (!this.appConfig) {
      throw Error('Config file not loaded!');
    }

    return `${this.secure ? 'https' : 'http'}://${this.host}${this.port.rest}${this.rapi}`;
  }
}
