import { Observable } from 'rxjs';

export enum LuminaireState {
    /* [SOO 20190811] dont change this one
    'OFF'         = 0,
    'ON'          = 1,
    'DEFAULT'     = 2,
    'MAINTENANCE' = 3, */
    'OFF' = 0,
    'ON' = 1,
    'DEFAULT' = 4,
    'MAINTENANCE' = 8,
    'DEVICE OFFLINE'=203,
    'GATEWAY OFFLINE'=780,
    'RESPOND TIMEOUT'=201,
  }

  
 export interface Luminaire {
   _id?: string ;
   type?: string;
   polen?: string;
   nickn?: string;
   //loctn?: Types.ObjectId | Location;
   addr?: string;
   lamp_ctrl_id?: string;
   location?: {
      x?: number,
      y?: number,
   };
   cal_id?:string;
   street?:string,
   region?:string,
   state?:string,
   country?:string,
   full_address?:string,
   bright?: number;
   status?: LuminaireState;
   online?: number;
   gateway?: string;
   local_time?: string;
   latest_time?: string;
   asset_lamp?: string,
   dimmable?: boolean,
   //group?: Types.ObjectId | Group;
   sensor?: {
      nxt_metering?: boolean,
      pre_metering?: number,
      metering?: number,
   };
   fixtures?: {
      luid?: string;
      nema?: {
         uid?: string,
         lamp_ctrl_id?: string,
         addr?: string,
      };
      driv?: {
         uid?: string,
         burnd?: number
      };
      light_source?: { burnd?: number };
   };
   phase?: string,
   loop?: string,
   voltage?: number,
   current?: number,
   temp?: number,
   activePwr?: number,
   project?:string,
   job?: string,
   customer?: string,
   burnHrsLeft?: number;
   category?:string,
    lifetime?:number,
     warr_years?:number,
     create_date?: string,
     install_date?: string,
     warr?: number,
     associates?:[string],
     master?: string;
}

  
  export abstract class LuminaireData {
    abstract observer(): Observable<Luminaire[]>;
    abstract luminaires(): Luminaire[];
    abstract updateLuminaire(luminaire: Luminaire[]): Promise<Luminaire[]>;
    abstract createLuminaire(luminaire: Luminaire): Promise<Luminaire>;
    abstract deleteLuminaire(luminaire: Luminaire[]): Promise<any>;
    abstract associateLuminaire(group: {}): Promise<any>;
    abstract getLuminairesFilter(filter:{}): Promise<any>

  }