import { Injectable } from '@angular/core';
import { Job, JobData } from '../data/job';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from './app-config.service';


@Injectable({
  providedIn: 'root'
})
export class JobService extends JobData{


  subject: BehaviorSubject<Job[]> = new BehaviorSubject<Job[]>(null);

  constructor(
    private http: HttpClient,
    private appConfig: AppConfig) {
    super();

    this.findJob().then((res: Job[]) => {
      console.log("[JobService] Job:", res);
      if (res.length > 0) {
        res.forEach(Job => {

        })
        this.subject.next(res);
      }
    })
  }


  observer() {
    return this.subject.asObservable();
  }

  jobs() {
    return this.subject.value;
  }

  async findJob() {
    try {
      const httpOptions = {
        withCredentials: true,
      };

      return await this.http.get<Job[]>(this.appConfig.baseUrl + '/job', httpOptions).toPromise();
    } catch (err) {
      throw err;
    }
  }

  async createJob(job: Job) {

    try {
      const httpOptions = {
        withCredentials: true,
      };
      console.log("new --> ", job)
      return await this.http.post<Job>(this.appConfig.baseUrl + '/job', job, httpOptions).toPromise();
    } catch (err) {
      throw err;
    }
  }
  async updateJob(job: Job[]) {
    try {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        withCredentials: true,
      };


      return await this.http.post<Job[]>(this.appConfig.baseUrl + '/job/update', job, httpOptions).toPromise();
    } catch (err) {
      throw err;
    }
  }

  async deleteJob(job: Job[]) {
    try {
      let jobs = []
      job.forEach(p=>{
        jobs.push('"'+p._id+'"');
      })
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        withCredentials: true,
      };

      console.log(jobs)
      return await this.http.delete<any>(this.appConfig.baseUrl + '/job/[' + jobs + ']', httpOptions).toPromise();
    } catch (err) {
      throw err;
    }
  }

  async addAsset(info:{},options:{mode:string,count:number}) {

    try {
      const httpOptions = {
        withCredentials: true,
      };
      console.log("new --> ", info)
      
      return await this.http.post<Job[]>(this.appConfig.baseUrl + '/job/'+options.mode+'/'+options.count, info, httpOptions).toPromise();
    } catch (err) {
      throw err;
    }
  }
}
