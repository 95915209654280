import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authService.currentUser;
    console.log(currentUser)

    if (currentUser) {
      if (route.data.roles) {
        console.log(route.data.roles)

        if (!route.data.roles.find((val: string) => this.authService.authorized(val))) {
          //console.log(state.url.substr(0, state.url.lastIndexOf('/')));

          this.router.navigate([state.url.substr(0, state.url.lastIndexOf('/'))]);
          return false;
        }
      }

      // logged in so return true
      //this.router.navigate(['/private']);
      //return false;
      return true;
    }

    // not logged in so redirect to login page with the return url
    //alert(state.url);
    this.router.navigate(['/public'], { queryParams: { returnUrl: state.url } });
    //this.router.navigate(['/public']);
    return false;
  }
  /*   canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      const currentUser = this.authService.currentUser;
  
      if (currentUser) {
        if (route.data.roles) {
          console.log(route.data.roles)
  
          if(!route.data.roles.find((val:string) => this.authService.coordinator(val))) {
            //console.log(state.url.substr(0, state.url.lastIndexOf('/')));
            
            this.router.navigate([state.url.substr(0, state.url.lastIndexOf('/'))]);
            return false;
          }
        } 
        // logged in so return true
        //this.router.navigate(['/private']);
        //return false;
        return true;
      }
  
      // not logged in so redirect to login page with the return url
      this.router.navigate(['/public'], { queryParams: { returnUrl: state.url } });
      return false;
    } */
}
