import { Injectable } from '@angular/core';
import { ProjectData, Project } from '../data/project';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from './app-config.service';
import { Customer } from '../data/customers';


@Injectable({
  providedIn: 'root'
})
export class ProjectService extends ProjectData {


  subject: BehaviorSubject<Project[]> = new BehaviorSubject<Project[]>(null);

  constructor(
    private http: HttpClient,
    private appConfig: AppConfig,

  ) {
    super();

    this.findProject().then((res: Project[]) => {
      console.log("[ProjectService] Project:", res);
      if (res.length > 0) {
        res.forEach(project => {

        })
        this.subject.next(res);
      }
    })
  }


  observer() {
    return this.subject.asObservable();
  }

  projects() {
    return this.subject.value;
  }

  async findProject() {
    try {
      const httpOptions = {
        withCredentials: true,
      };

      return await this.http.get<Project[]>(this.appConfig.baseUrl + '/project', httpOptions).toPromise();
    } catch (err) {
      throw err;
    }
  }

  async createProject(project: Project) {

    try {
      const httpOptions = {
        withCredentials: true,
      };
      console.log("new --> ", project)
      return await this.http.post<Project[]>(this.appConfig.baseUrl + '/project', project, httpOptions).toPromise();
    } catch (err) {
      throw err;
    }
  }
  async updateProject(project: Project[]) {
    try {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        withCredentials: true,
      };


      return await this.http.post<Project[]>(this.appConfig.baseUrl + '/project/update', project, httpOptions).toPromise();
    } catch (err) {
      throw err;
    }
  }

  async deleteProject(project: Project[]) {
    try {
      let projects = []
      project.forEach(p=>{
        projects.push('"'+p._id+'"');
      })
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        withCredentials: true,
      };

      console.log(projects)
      return await this.http.delete<any>(this.appConfig.baseUrl + '/project/[' + projects + ']', httpOptions).toPromise();
    } catch (err) {
      throw err;
    }
  }



}


