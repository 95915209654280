import { Observable } from 'rxjs';

export interface Project {
  _id?:  string,
  name: string;                      // mac address
  refn: string;                     
  creationDate?: string;
  startDate?: string;  
  endDate?: string;                     // nickname
  pic?: string; 
  worker?:string[]
  region?:string,
  customer?: string;
}

export abstract class ProjectData {
    abstract observer(): Observable<Project[]>; 
    abstract projects(): Project[]; 
    abstract createProject(project: Project): Promise<any>
    abstract updateProject(project: Project[]): Promise<Project[]>;
    abstract deleteProject(project: Project[]): Promise<any>
}
