import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Sensor } from '../data/sensor';
import { AppConfig } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class SensorService {
  subject: BehaviorSubject<Sensor[]> = new BehaviorSubject<Sensor[]>(null);

  constructor(
    private http: HttpClient,
    private appConfig: AppConfig,
  ) {

this.findSensor().then((sensors:Sensor[])=>{
if(sensors){
  sensors.forEach(sensor => {
    if(!sensor.location)
    {
      sensor.location={
        x:null,
        y:null
      }
    }
  });
this.subject.next(sensors)

}
}, err => {
  console.error(err.message);
})

   }


  async findSensor() {
    try {
      const httpOptions = {
        withCredentials: true,
      };
      //console.log(this.appConfig.baseUrl + '/sensor')
      return await this.http.get<Sensor[]>(this.appConfig.baseUrl + '/sensor', httpOptions).toPromise();
    } catch (err) {
      throw err;
    }
  }
  observer() {
    return this.subject;
  }

  sensors() {
    return this.subject.value;
  }
  async createSensor(sensor: Sensor) {

    try {
      const httpOptions = {
        withCredentials: true,
      };
      console.log("new --> ", sensor)
      return await this.http.post<Sensor>(this.appConfig.baseUrl + '/sensor', sensor, httpOptions).toPromise();
    } catch (err) {
      throw err;
    }
  }
  async updateSensor(sensor: Sensor[]) {
    try {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        withCredentials: true,
      };

      return await this.http.post<Sensor[]>(this.appConfig.baseUrl + '/sensor/update',sensor, httpOptions).toPromise();
    } catch (err) {
      throw err;
    }
  }
  
  async deleteSensor(sensor: Sensor) {
    try {

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        withCredentials: true,
      };

      return await this.http.delete<Sensor>(this.appConfig.baseUrl + '/sensor/' + sensor._id , httpOptions).toPromise();
    } catch (err) {
      throw err;
    }
  }
}